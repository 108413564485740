.home-container {
	background-image: url('../../Images/Home-bg.svg');
	background-size: cover;
}

.text-align-left {
	text-align: left;
}

.cta-div {
	margin-bottom: 180px;
}

.rec-h2-search {
	margin-top: 60px;
	margin-bottom: 80px;
}

.tutor-list-section .book-tutor {
	/* width: 75%; */
}
.navbar-nav {
	display: flex;
	align-items: center;
}

.navbar-nav .menu-item {
	color: #1a1b1e;
	font-weight: 500;
	font-size: 18px;
}

.navbar-nav .login-button {
	margin-top: 0;
	margin-right: 0;
}

.numbering-steps {
	border-radius: 100%;
	background: #ffffff;
	box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.07);
	height: 56px;
	width: 56px;
	position: absolute;
	left: -14px;
	top: 20px;
	text-align: center;
	line-height: 56px;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
}

.main-writing {
	margin-top: 30px;
}

.home-top-rtl {
	direction: rtl;
}

/* SideNav */
/* Individual item */
.bm-item {
	display: inline-block;

	/* Our sidebar item styling */
	text-decoration: none;
	margin-bottom: 10px;
	color: #d1d1d1;
	transition: color 0.2s;
}

/* Change color on hover */
a.bm-item:hover {
	color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
	display: none;
	position: absolute;
	width: 36px;
	height: 30px;
	right: 36px;
	top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
	background: #fff;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
}
.bm-menu .header-item {
	margin-left: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}

/* Navbar */

.navbar-custom {
	background-color: #ffffff;
}

.login-button {
	font-family: 'Poppins';
	padding: 16px 28px 16px 28px;
	background-color: #f44747;
	color: white;
	border-radius: 12px;
	margin-top: 10px;
	font-weight: 500;
	font-size: 16px;
	margin-right: -30px;
	text-align: center;
}

.godash-button:hover {
	transform: translate(10px);
}

.login-text {
	font-size: 15px;
}

.student-portal {
	font-weight: bold;
	font-size: 13px;
}

.navbar-nav > li {
	margin-left: 20px;
	margin-right: 20px;
}

.admin-navbar .navbar-nav > li {
	margin-left: 10px;
	margin-right: 10px;
}

.header-image {
	width: 100px;
	margin-top: 10px;
}

.header-custom {
	padding-bottom: 50px;
}

.header-item {
	margin-top: 24px;
	margin-left: 30px;
	color: #1a1b1e;
	font-weight: 500;
	font-size: 18px;
}

.header-item:hover {
	color: #000000;
	text-decoration: none;
}

.main-container {
	/* padding: 50px 30px 0 30px; */
	padding: 30px 0px;
}

.third-container {
	text-align: center;
}

.fourth-container {
	text-align: center;
}

.home-blub {
	/* background-color: white; */
	border-radius: 35px;
	padding: 15px;
	/* box-shadow: 0 1px 3px 0 rgb(194, 195, 196); */
	text-align: left;
}

.request-tutor {
	right: 30px;
	bottom: 40px;
	position: fixed;
	padding: 12px 22px 12px 22px;
	font-size: 17px;
	background-color: rgb(219, 80, 80);
	color: white;
	border-radius: 50px;
	z-index: 2;
}

.request-tutor:hover {
	transform: scale(1.03);
	cursor: pointer;
}

/* Top Page*/

.main {
	margin-bottom: 100px;
	position: relative;
}

.main-text {
	text-align: left;
	font-weight: bold;
	font-size: 44px;
	color: #1a1b1e;
	line-height: 140%;
}

.main-paragraph {
	margin-top: 20px;
	font-size: 18px;
	font-weight: 400;
	color: #4b4c52;
	text-align: left;
}

.main-image {
	width: 95%;
	margin-left: 70px;
	margin-top: 10px;
}

.main-video-div {
	position: relative;
	padding-bottom: 62.5%;
	height: 0;
}

.main-video {
	position: absolute;
	top: 0;
	left: 0;
	width: 570px;
	max-width: 96vw;
	max-height: 100%;
	height: 427px;
	border: none;
	margin-top: 30px;
	margin-left: 20px;
	border-radius: 28px 0px;
	box-shadow: 0 1px 3px 0 rgb(194, 195, 196);
}

.relative-el {
	background-color: #f44747 !important;
}

.orange-box {
	position: absolute;
	top: 12px;
	left: 0px;
	background-color: #f44747;
	width: 220px;
	height: 165px;
	border-radius: 28px 0px 20px;
}

.parent-main-image {
	width: 80%;
	position: relative;
	margin-left: 70px;
	margin-top: -20px;
	border-radius: 20px;
}

.book {
	background-color: #fd5e53;
	margin: 110px 70px 70px 30px;
	border-radius: 20px;
	padding: 20px 60px;
	border-width: 0;
}

.berkeley {
	margin-left: 10px;
	margin-bottom: 45px;
	opacity: 0.8;
}

.berkeley-made {
	width: 5%;
	margin-left: 3px;
}

.blub-container {
	border-radius: 27px;
	box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
	text-align: center;
	margin: 50px;
}

.blub-container:hover {
	transform: scale(1.01);
	transition-duration: 0.4s;
}

.subjects-div {
	margin: 60px 0 60px 0;
}

.a-subject {
	margin: 15px 15px 15px -2px;
	padding: 10px 15px 10px 15px;
	box-shadow: 0 1px 3px 0 rgb(177, 178, 179);
	background-color: #f8f8f8;
	color: black;
}

.help-on {
	font-size: 30px;
	font-weight: 500;
}

/* How it Works */
.how-it-works-container {
	margin: 32px 15px 50px 15px;
	text-align: center;
}

.how-title {
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 45px;
	padding-top: 45px;
}

.how-it-works {
	margin: 50px 0 50px 0;
}

.second-images {
	width: 26%;
}

.how-img {
	font-size: 55px;
}

.second-images:hover {
	transform: rotate(10deg);
	transition-duration: 0.2s;
}

.sc-text {
	padding-top: 45px;
}

.sc-paragraph {
	/* margin: 15px 50px 0 50px; */
	opacity: 0.8;
}

/* Meet tutors */

.a-tutor-home {
	border-radius: 50px;
	margin-bottom: 40px;
	padding-top: 25px;
	height: auto;
	border-radius: 24px;
	padding: 28px;
	background: #ffffff;
}

.a-tutor-home * {
	text-align: left;
}

.tutor-home-image {
	width: auto;
	height: 85px;
	max-width: min-content;
	border-radius: 24px;
	margin-bottom: 24px;
}

.tutor-name {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 0px;
}

.tutor-name i {
	padding-left: 11px;
	width: 29px;
	height: 29px;
}

.a-tutor-home h5 {
	font-size: 16px;
	font-weight: 500;
	color: #9b9b9b;
}

.third-section {
	text-align: center;
}

.tutor-row {
	padding-left: 105px;
	padding-right: 105px;
}

.meet-tutors-title {
	margin: 100px 23% 80px 23%;
	font-size: 32px;
	font-weight: 700;
}

.home-tutors {
	padding: 40px;
	padding-bottom: 0px;
	text-align: left;
}

.third-container .request-typeform {
	margin-top: 0;
}

.home-view-profile {
	border-radius: 15px;
	padding: 10px;
	font-size: 14px;
	margin-bottom: 30px;
	background-color: #f0f0f0;
}

.tutor-hours {
	opacity: 0.8;
}

/* Why College Student Section */
.why-college-student-container {
	margin: 130px;
}

.why-college-student-container h2 {
	font-weight: 600;
	font-size: 28px;
	color: #1a1b1e;
}

.why-college-student-container img {
	margin-bottom: 24px;
}

.why-college-student-container-context {
	padding: 40px;
}

.college-header {
	font-size: 40px;
	margin-bottom: 80px;
	font-weight: 600;
}

.college-img {
	width: 30%;
	margin-bottom: 15px;
}

.college-explain {
	font-size: 18px;
	margin: 10px 60px 60px 0;
	color: #4b4c52;
	line-height: 32.4px;
}

.number {
	background-color: rgb(240, 240, 240);
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	color: rgb(15, 15, 15);
	border-radius: 100%;
	width: 60px;
	padding: 10px 0 10px 0;
	text-align: center;
	font-size: 25px;
	font-weight: 800;
	margin-bottom: 25px;
}

/* Whiteboard Section */

.whiteboard-container {
	margin-top: 35px;
	margin-bottom: 35px;
}

.whiteboard-frame {
	width: 680px;
	height: 490px;
	border-radius: 15px;
	box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 670px) {
	.whiteboard-frame {
		width: 380px;
		height: 320px;
	}

	.cta-div {
		margin-bottom: 250px;
	}

	.text-align-left {
		text-align: center;
	}

	.cta-section .meet-tutors-title {
		text-align: center;
		float: none !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
}

/* CTA Section */

.cta-section {
	text-align: center;
	margin: 0;
	background-color: #fafafa;
}

.cta-button {
	background-color: #fd5e53;
	border-radius: 20px;
	padding: 21px 80px;
	margin-left: 15px;
	float: left;
	margin-top: 32px;
	margin-bottom: 270px;
	border-width: 0;
	color: white;
}

.cta-text {
	color: gray;
}

.cta-img-div {
	place-content: center;
	margin-bottom: 100px;
}

.cta-img {
	height: 234px;
	width: 234px;
	margin-top: 70px;
}

.cta-section .meet-tutors-title {
	font-size: 30px;
	margin-top: 120px;
	margin-bottom: 0px;
	margin-left: 0px;
	float: left;
}

@media (max-width: 768px) {
	.cta-section .meet-tutors-title {
		text-align: left;
	}
}

.cover-footer {
	margin-bottom: -310px;
}

/* Footer */

.footer-section {
	text-align: center;
	background-color: black;
}

.footer-icon {
	padding-top: 15px;
	align-items: center;
	text-align: center;
}

.heart-footer {
	color: #fd5e53;
	padding-top: 20px;
	animation: heartbeat 1s infinite;
}

.made-with {
	color: white;
	font-size: 28px;
}

.social-section {
	margin-top: 30px;
}

.social-icons-div {
	padding: 6px 12px 6px 12px;
	font-size: 20px;
	margin: 6px;
	height: 44px;
	width: 44px;
	border-radius: 12px;
	background: rgba(255, 255, 255, 0.14);
}

.social-icons {
	color: white;
}

.social-icons:hover {
	transform: scale(1.2);
	transition-duration: 0.4s;
}

.become-tutor {
	margin-top: 60px;
	background-color: white;
	color: black;
	padding: 16px 28px 16px 28px;
	margin-bottom: 25px;
	font-weight: 500;
	font-size: 16px;
}

.copyright {
	color: rgb(168, 168, 168);
	font-size: 14px;
	margin-top: 15px;
}

.copyright a {
	color: #fd5e53;
}

@keyframes heartbeat {
	0% {
		transform: scale(0.75);
	}

	20% {
		transform: scale(1);
	}

	40% {
		transform: scale(0.75);
	}

	60% {
		transform: scale(1);
	}

	80% {
		transform: scale(0.75);
	}

	100% {
		transform: scale(0.75);
	}
}

/* BECOME TUTOR PAGE */

.tutor-made {
	width: 28%;
	margin-right: 30px;
	margin-left: 20px;
	margin-top: 15px;
}

.tutor-image {
	margin-left: 20px;
}

.cta-color {
	background-color: white;
}

/* Terms and Conditions page */

.terms-title {
	text-align: left;
	margin-bottom: 50px;
}
.terms-title h1 {
	font-weight: 700;
	font-size: 32px;
}

.terms-text {
	width: 100%;
	max-width: 1024px;
	padding: 20px 15px;
	margin: 0 auto;
	color: #000;
}

.terms-text h4 {
	font-weight: 600;
	font-size: 22px;
	margin: 20px 0 15px;
}

.terms-link {
	color: darkgray;
}

.terms-link:hover {
	color: white;
	text-decoration: none;
}

/* Peerlinc Method Page */

.method-button-center {
	text-align: center;
	padding-bottom: 75px;
}

/* No Match Page */

.no-match {
	text-align: center;
	margin: 0 40px 70px 40px;
}

.error-image {
	width: 25%;
	margin-bottom: 70px;
}

.error-button {
	background-color: rgb(39, 38, 38);
	color: white;
}

/* SAT Page */

.learn-sat {
	text-align: center;
}

/* Sucess Page */

.success-page {
	text-align: center;
	margin-bottom: 80px;
}

.success-video {
	border: 0;
	border-radius: 15px;
	height: 237px;
}

.success-title {
	margin-bottom: 15px;
	font-size: 65px;
}

.success-text {
	font-size: 22px;
}

/* Prices/Reviews Section */

.review-user {
	font-size: 49px;
	margin-right: 10px;
}

.reviews-text {
	margin: 100px 60px 30px 60px;
	font-size: 45px;
}

.all-reviews {
	text-align: center;
	margin: 30px 100px 0 100px;
}

.a-review {
	font-weight: 400;
	font-size: 16px;
	color: #4b4c52;
	margin: 0 20px 50px 20px;
	background: #ffffff;
	box-shadow: 0px 14px 31px rgba(0, 0, 0, 0.05);
	padding: 32px;
	border-radius: 20px;
	text-align: left;
}

.review-name {
	line-height: 23px;
	font-size: 19px;
	font-weight: 600;
	color: rgb(54, 54, 54);
}

.prices-check {
	color: #eb4236;
}

.reviews:hover {
	text-decoration: underline;
	text-decoration-color: rgb(44, 43, 43);
}

.review-paragraph {
	font-weight: 600;
	font-size: 18px;
	color: #f44747;
}

.review-paragraph i {
	margin-left: 6px;
}

.review-star {
	color: #fbd46d;
	font-size: 30px;
	letter-spacing: 7px;
	margin-top: 15px;
	margin-bottom: 20px;
}

.review-star:hover {
	transform: scale(1.08);
}

.review-click:hover {
	text-decoration: none;
}

/* Home Video */

.play {
	position: absolute;
	top: 50%;
	left: 55%;
	transform: translate(-50%, -50%);
	font-size: 65px;
	color: rgb(255, 255, 255);
}

.play-parent {
	color: rgb(245, 245, 245);
}

.play:hover {
	color: rgb(39, 39, 39);
	cursor: pointer;
}

.video-modal-container {
	text-align: center;
	margin: 15% auto;
	width: 540px;
	height: 338px;
	border-radius: 15px;
}

.video {
	width: 100%;
	height: 100%;
	border: 0;
	border-radius: 30px;
}

.close-video {
	color: rgb(0, 0, 0);
	float: right;
	font-size: 28px;
	font-weight: bold;
	margin-bottom: -25px;
	margin-right: -8px;
	z-index: 2;
	position: relative;
}

.close-video:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.home-tutors .carousel-control-prev,
.home-tutors .carousel-control-next {
	background: #f44747;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	opacity: 1;
	top: 50%;
	transform: translateY(-50%);
}

.home-top-rtl .main-video-div {
	text-align: center;
}

.home-top-rtl .home-banner-image {
	width: 100%;
	max-width: 500px;
	margin-top: -40px;
}

.btn-custom-primary {
	padding: 15px 25px;
	background-color: #f44747;
	color: white;
	border-radius: 12px;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	display: inline-block;
}
.btn-custom-primary:hover {
	background-color: #eb4236;
	color: #fff;
	text-decoration: none;
}

@media (max-width: 768px) {
	.home-tutors .carousel-control-prev,
	.home-tutors .carousel-control-next {
		display: none;
	}

	.third-container .carousel-inner {
		height: 470px;
	}

	.main-writing {
		margin-bottom: 0px;
		padding-top: 50px;
	}

	.tutor-row {
		padding: 0px;
		height: 800px;
	}

	.a-tutor-home {
		padding-left: 0px;
		padding-right: 0px;
		padding: 22px;
	}

	.meet-tutors-title {
		margin-left: 0px;
		margin-right: 0px;
		text-align: center;
	}

	.why-college-student-container {
		margin-left: 15px;
		margin-right: 15px;
	}

	.why-college-student-container .college-explain {
		margin-right: 0px;
	}

	.all-reviews {
		margin-left: 15px;
		margin-right: 15px;
	}

	.navbar-nav {
		display: none;
	}

	.bm-burger-button {
		display: block;
	}
}

/* Phone Responsive */

@media (max-width: 470px) {
	.third-container .carousel-inner {
		height: 670px;
	}

	.a-review {
		margin-bottom: 20px;
	}

	.main-video {
		margin-top: 00px;
	}

	.header-custom {
		padding-bottom: 0px;
	}

	.main {
		display: block;
		width: 100vw;
	}

	.main-video-div {
		display: none;
		margin-left: auto;
		margin-right: auto;
	}

	.orange-box {
		display: none;
	}

	.main-writing {
		margin-top: -55px;
	}

	.main-text {
		margin-left: 0;
		font-size: 40px;
	}

	.main-paragraph {
		margin-left: 2px;
		font-size: 17px;
		margin-bottom: -30px;
	}

	.berkeley {
		margin-bottom: 90px;
		margin-left: 10px;
	}

	.home-tutors {
		padding: 0;
		/* height: 700px; */
		/* margin-bottom: -44px; */
	}

	.subject-home-div {
		margin-left: 10px;
	}

	.whiteboard-text {
		display: none;
	}

	.whiteboard-container {
		margin-top: 0;
	}

	.whiteboard-image {
		width: 80%;
	}

	.subjects-div {
		margin-left: 0;
	}

	.why-college-student-container {
		margin: 15px;
	}

	.meet-tutors-title {
		font-size: 32px;
		margin: 60px 4% 30px 4%;
	}

	.why-college-student-container .meet-tutors-title {
		margin: 60px 0% 30px 0%;
	}

	.all-reviews {
		margin: 10px 15px 10px 15px;
	}

	.reviews-text {
		margin: 0 20px 30px 20px;
		font-size: 35px;
	}

	.home-tutors {
		margin-left: 15px;
		margin-right: 15px;
		/* padding-left: 15px; */
		/* text-align-last: center; */
	}

	.home-blub {
		margin-left: 15px;
	}

	.a-tutor-home {
		margin-bottom: 30px;
		padding-left: 15px;
		height: auto;
	}

	.tutor-home-image {
		width: 28%;
		height: auto;
		border-radius: 100%;
	}

	.why-college-student-container {
		margin-top: 140px;
	}

	.carousel-control-prev,
	.carousel-control-next {
		display: none;
	}
}
