/* Buy */

.buy-modal-content {
	margin: 0 20px 0 20px;
}

.buy-content-text {
	text-align: left;
	margin-left: 15%;
}

.a-package-details {
	font-size: 13px;
}

.buy-content-header {
	font-size: 17.5px;
	font-weight: 450;
}

.buy-select-button {
	background-color: white;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	border: 0;
	margin-top: 5px;
	color: black;
}
.buy-select-button:focus .select-icon {
	color: white;
}
.buy-select-button:focus {
	background-color: #202020;
	color: white;
}
.buy-select-button:active .select-icon {
	color: white;
}

.confirmation-container {
	margin: 5px;
	margin-bottom: 35px;
}

.confirmation-card-container {
	text-align: center;
}

.confirmation-header {
	font-size: 22px;
	font-weight: bold;
	padding-bottom: 10px;
	padding-top: 30px;
}

.confirmation-total {
	font-size: 19px;
	padding-top: 15px;
	padding-bottom: 25px;
}

.pay {
	margin-top: 65px;
	margin-bottom: 30px;
	padding: 10px 60px 10px 60px;
}

.StripeElement {
	box-sizing: border-box;

	height: 40px;

	padding: 10px 12px;

	border: 1px solid transparent;
	border-radius: 4px;
	background-color: white;

	box-shadow: 0 1px 3px 0 #d3d7db;
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
}

.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

.powered-by-stripe {
	opacity: 0.8;
	margin-bottom: 0;
}
.stripe-img {
	width: 45px;
}

/* Phone */

@media (max-width: 470px) {
	.buy-content-header {
		font-size: 17px;
	}
	
	.a-package-details {
		font-size: 11px;
	}

	.buy-select-button {
		font-size: 13px;
	}

	.buy-select-button:hover {
		background-color: #202020;
		color: white;
	}
}
