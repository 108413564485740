.contact-input {
	margin-bottom: 35px;
	border: 0;
	width: 230px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
	background-color: rgb(250, 250, 250);
}

.contact-textarea {
	margin-bottom: 35px;
	border: 0;
	width: 230px;
	height: 200px;
	resize: none;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
	background-color: rgb(250, 250, 250);
}

.contact-label {
	font-size: 16px !important;
	margin-bottom: 10px;
	font-weight: 500;
}

.contact-type {
	display: none;
}

.contact-input:focus {
	border: 0;
	outline: 0;
}

.contact-label {
	font-size: 13px;
}

.contact-image {
	margin-left: -300px;
	margin-top: 70px;
	width: 600px;
	height: auto;
}

.contact-button {
	background-color: #eb6056;
	color: white;
	padding: 10px 55px 10px 55px;
	margin-top: 25px;
}

.contact-title {
	font-size: 45px;
	margin-bottom: 10px;
}

.contact-paragraph {
	font-size: 17px;
    margin-bottom: 55px;
    font-weight: 300;
}


/* BUG FORM */

.get-help {
	box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
	background-color: white;
	color: black;
	border-radius: 10px;
	padding: 8px;
	bottom: 25px;
	left: 20px;
	position: fixed ;
	z-index: 1;
}