.transaction-page {
	background: #fbfbfb;
	margin: 100px 0;
	box-shadow: none;
	border-radius: 0;
}

.transaction-page .card {
	background: #ffffff;
	box-shadow: 0px 29px 98px -4px rgba(0, 0, 0, 0.04);
	border-radius: 16px;
	border: none;
	text-align: left;
	margin-top: 10px;
	margin-bottom: 20px;
}

.transaction-page .card .card-body {
	padding: 35px 20px;
}

.transaction-page .card .card-title {
	color: #000000;
	font-family: Poppins;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
}

.transaction-page .card p {
	color: #4b4c52;
	font-family: Poppins;
}

.transaction-page .card p.txt-title {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 8px;
}

.transaction-page .card p.txt-value {
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
}

.transaction-page .card .btn-cancel {
	color: #f44747;
	background-color: transparent;
	border: none;
	padding: 0;
}

.transaction-page .card h4 {
	font-size: 20px;
	margin: 20px 0 15px;
}

.transaction-page .transactions-container h2 {
	font-size: 20px;
	text-align: left;
	margin-bottom: 0;
}

.transaction-page .transactions-container .all-labels {
	margin-top: 15px;
}

.transaction-page .a-data .col-4 {
	word-break: break-word;
}

.text-student-name {
	font-weight: 600;
	font-size: 22px;
	margin-bottom: 40px;
	text-align: center;
}

.text-current-date {
	text-align: right;
	font-weight: 400;
	font-size: 16px;
	color: #4b4c52;
	margin-bottom: 20px;
}
