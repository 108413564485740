.section-math-gpt {
    .section-header {
        margin-bottom: 0;

    }

    .section-content {
        height: calc(100% - 80px);
        overflow-y: scroll;

        .chat-preview-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;

            h4 {
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 20px;
            }

            .sample-item {
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                border-radius: 8px;
                font-weight: 400;
                font-size: 14px;
                color: #575757;
                text-align: center;
                margin-bottom: 20px;
                padding: 15px 15px;
                width: 100%;
                max-width: 350px;
            }
        }

        .chatview__chatarea {
            padding: 15px 20px;
            min-height: 100%;

            .message {
                padding: 15px 20px 10px;
                border: 1px solid #DDDDDD;
                margin: 10px 0;
                border-radius: 12px 12px 0px 12px;
                width: 100%;
                max-width: calc(100% - 40px);
                float: right;

                &.ai-response {
                    background: #FFEAEA;
                    border: 1px solid #FFCFCF;
                    border-radius: 12px 12px 12px 0;
                    float: left;
                }

                &.user-text {
                    .message__wrapper {
                        font-weight: 600;
                    }
                }

                .message__wrapper {
                    display: flex;
                    position: relative;
                    font-size: 14px;

                    .message__markdown {
                        width: 100%;
                        overflow: hidden;

                        .language-js {
                            white-space: pre-line !important;
                            word-break: normal !important;
                        }
                    }
                }
            }

            .message__createdAt {
                font-size: 10px;
                text-align: right;
            }

            .message__senderName {
                font-size: 10px;

            }
        }
    }

    .section-footer {
        margin-top: 10px;
        padding: 0 20px;

        .box-chat-input {
            background: #F6F1F1;
            border-radius: 8px;
            padding: 5px 20px;

            form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 15px;

                select {
                    width: 120px;
                    height: 62px;
                }

                .form-control {
                    background-color: transparent;
                    border: none;
                    padding: 10px 0;
                    font-size: 14px;
                    resize: none;
                }

                .form-control:focus {
                    outline: none;
                    box-shadow: none;
                }

                button {
                    margin-left: 10px;
                    background-color: transparent;
                    border: none;
                    padding: 0;

                    img {}
                }
            }
        }
    }
}

.react-tooltip {
    background: #fff !important;
    color: #000 !important;
    width: 100% !important;
    max-width: 400px !important;
    border-radius: 8px !important;
    padding: 15px 20px !important;
    opacity: 1 !important;

    filter: drop-shadow(0px -4px 22px rgba(0, 0, 0, 0.11));
}