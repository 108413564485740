.card-container {
    position: relative;
    text-align: center;
    background: #ffffff;
    border: 2px solid #f0f0f0;
    border-radius: 20px;
    padding: 24px 10px;
    height: fit-content;

    .price-info {
        p.text-title {
            text-transform: uppercase;
            color: #87878D;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 8px;
        }

        p.text-price {
            color: #1A1B1E;
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 5px;
        }

        p.text-type {
            color: #1A1B1E;
            font-weight: 400;
            font-size: 14px;
        }
    }
}


@media (max-width: 768px) {
    .card-container {
        margin-bottom: 30px;
    }
}