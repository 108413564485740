/* Site as a whole */

html,
body {
	color: #323233;
	max-width: 100%;
	overflow-x: hidden;
	font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
	color: #3f3f44;
}

button {
	outline: none;
	border: none;
	border-radius: 16px;
	color: white;
	background-color: #2c2b2b;
	transition: 0.1s;
	padding: 15px;
}

button:hover {
	transform: scale(1.015);
	cursor: pointer;
	background-color: none;
}

button:focus {
	outline: none !important;
	border: 0;
}

.button-disabled {
	opacity: 0.2;
}

.button-disabled:hover {
	transform: none;
	cursor: not-allowed !important;
}

.action-button {
	background-color: #ee695f;
	margin: 50px 70px 70px 30px;
	border-radius: 15px;
	padding: 20px 60px;
	border-width: 0;
}

.general-container {
	background-color: white;
	border-radius: 27px;
	padding: 15px;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	text-align: center;
}

.href-custom:hover {
	text-decoration: none;
}

.center-it {
	text-align: center;
}

.left-it {
	text-align: left;
}

.hide-it {
	display: none;
	visibility: hidden;
}

/* Modal */

.modal-content {
	max-width: 96vw;
	border-radius: 15px !important;
}

@media (max-width: 470px) {
	.modal {
		padding-left: 0px;
		padding-right: 0px;
	}
}

.modal-pick {
	border-radius: 10px;
	box-shadow: 0 1px 4px 0 rgba(43, 42, 42, 0.2);
	padding: 8px;
	margin-top: 10px;
}

.modal-pick:hover {
	transform: scale(1.1);
	transition-duration: 0.2s;
	cursor: pointer;
}

.modal-pick:focus {
	background-color: #252525;
	outline: none;
	color: white;
}

.picked {
	background-color: #252525;
	color: white;
}

/* Loading */

.loading-div {
	text-align: center;
	margin-top: 200px;
}

/* Route Links */
.link {
	color: white;
}

.link:hover {
	text-decoration: none;
	color: white;
}

/* Nothing Found */

.nothing-found {
	margin-top: 10%;
	text-align: center;
}

/* Check for Avail */
.tag-avail {
	margin-left: 320px;
	margin-top: 4.5px;
	position: absolute;
	transform: scale(0.7);
}

.tag-avail-settings {
	margin-left: 220px;
	margin-top: 32px;
	position: absolute;
	transform: scale(0.7);
}

.tag-avail-check {
	color: #21bf73;
	font-size: 30px;
}

.tag-avail-x {
	color: #fd5e53;
	font-size: 30px;
}

/* Colors */
.red {
	color: #fa5a4e;
}

.green {
	color: #21bf73;
}

.black {
	color: black;
}

.white {
	color: white;
}

.blue {
	color: rgb(100, 140, 248);
}

.orange {
	color: rgb(247, 170, 29);
}

.new-orange {
	color: #f44747;
}

.brown {
	color: rgb(172, 109, 68);
}

.gray {
	color: rgb(54, 54, 54);
}

/* Other */
.explain-text {
	font-size: 12px;
	opacity: 0.8;
	margin-top: 10px;
}

.align-left {
	text-align: left;
}

.modal-header-custom {
	text-align: center;
	font-size: 20px;
	margin: 0 30px 30px 30px;
}

.book-method {
	margin-top: 65px;
	text-align: center;
}

.loading-matches {
	margin-top: 150px;
}

.search-loading-div {
	max-width: 200px;
	margin: 50px auto 100px;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
}

.spinner-border {
	width: 2rem !important;
}

.gradient-wrapper {
	padding: 5px;
	margin: 0 auto;
	border-radius: 50%;
	display: inline-block;
	background: #5bab15;
	background: -webkit-linear-gradient(#5bab15, white);
	background: -o-linear-gradient(#5bab15, white);
	background: -moz-linear-gradient(#5bab15, white);
	background: linear-gradient(#5bab15, white);
	-webkit-animation: spinner-border 1s linear infinite;
	animation: spinner-border 1s linear infinite;
}

#maincircle {
	width: 100px;
	height: 100px;
	background: #fff;
	border-radius: 50%;
}

.search-loading-div p {
	font-size: 16px;
	margin: 8px 0;
}

.search-loading-div img {
	width: 45px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.search-loading-div .search-loader {
	position: relative;
}

.search-loading-section::before {
	content: ' ';
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1;
	display: block;
	background: rgba(255, 255, 255, 0.9);
	width: 100%;
	height: 100vh;
}

.tutor-list-section {
	min-height: 800px;
}

.subject-list {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 20px;
}
.no-found {
	margin-top: 80px;
}

.txt-center {
	text-align: center;
}

.refer-link {
	border-radius: 7px;
	top: 13px;
	line-height: 28px;
	background-color: #f44747;
	color: white;
	text-align: center;
	padding: 6px 20px;
	position: relative;
}
.refer-link:hover {
	background-color: #f44747;
	color: white;
	text-decoration: none;
	opacity: 0.9;
}

.a-data .row .text-data {
	overflow-wrap: break-word;
	font-size: 14px;
}

.btn.btn-sm {
	padding: 5px 10px;
	font-size: 12px;
	height: auto;
	border-radius: 6px;
	margin: 0 4px;
}

.data-table td {
	font-size: 14px;
}
.data-table th {
	font-size: 14px;
	font-weight: 600;
}
