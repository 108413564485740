.page-subscription {
    color: #1A1B1E;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    .pricing-header {
        max-width: 600px;
        margin: 10px auto 0;
        text-align: center;

        h2 {
            font-size: 40px;
            line-height: 60px;
            font-weight: 600;

            span {
                color: #5AAB15;
            }
        }

        p {
            font-size: 18px;
            line-height: 160%;
            margin-top: 15px;
        }
    }

    .price-section {
        margin: 20px 0 30px;
        display: flex;
        width: 100%;
        max-width: 600px;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background: #F5F3F3;
        border-radius: 30px;
        padding: 30px 20px;

        .pricing-box {
            box-shadow: 0px 19px 67px -7px rgba(0, 0, 0, 0.07);
            border-radius: 20px;
            width: 100%;
            background: #fff;
            position: relative;
            text-align: center;

            .box-header {
                padding: 30px 25px 10px;

                .txt-title {
                    text-transform: uppercase;
                    color: #87878D;
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 8px;
                }

                .txt-price {
                    color: #1A1B1E;
                    font-weight: 600;
                    font-size: 26px;
                    margin-bottom: 5px;
                }

                .txt-per-type {
                    color: #1A1B1E;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }

        .pricing-box.recommended {
            margin-top: 30px;
        }
    }

    .feature-section {
        .box-body {
            width: 100%;
            max-width: 600px;
            margin: 20px 0 30px;
            padding: 0 20px;

            ul {
                list-style: none;
                padding: 0;

                li {
                    list-style: none;
                    margin-bottom: 20px;

                    .fa {
                        color: #5BAB15;
                        font-size: 24px;
                        position: absolute;
                        margin-top: 0;
                    }

                    span {
                        width: calc(100% - 40px);
                        font-size: 20px;
                        font-weight: 300;
                        margin-left: 30px;
                        display: block;

                        strong {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .box-footer {
            width: 100%;
            text-align: center;
            margin-bottom: 80px;

            .btn-purchase {
                background: #F44747;
                color: #fff;
                height: 55px;
                border-radius: 12px;
                border: 1px solid #F44747;
                line-height: 50px;
            }
        }
    }

    .pricing-faq {
        margin: 50px auto 40px;
        max-width: 800px;

        .pricing-faq-header {
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            text-align: center;
            color: #1A1B1E;
            margin-bottom: 40px;
        }

        .dropdown-panel {
            background: #FFFFFF;
            box-shadow: 0px 12px 43px rgba(0, 0, 0, 0.04);
            border-radius: 16px;
            margin-bottom: 15px;
            padding: 25px 20px;
            position: relative;
            cursor: pointer;

            .txt-question {
                font-size: 20px;
                line-height: 30px;
                color: #1A1B1E;
                margin-bottom: 0;
                width: calc(100% - 50px);
            }

            .txt-answer {
                font-size: 18px;
                line-height: 27px;
                color: #4B4C52;
                margin-top: 15px;
            }

            .btn-action {
                position: absolute;
                top: 25px;
                right: 35px;

                .icon {
                    color: #F44747;
                    font-size: 24px;
                }
            }

            &.faq-active {
                .txt-question {
                    font-weight: 600;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .pricing-box.recommended {
            margin-top: 0;
        }
    }
}