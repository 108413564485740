.page-admin-videos {
    .admin-title {
        font-weight: 700;
        font-size: 28px;
    }

    .filter-bar {
        background: #EDEDED;
        border-radius: 63px;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;

        span {
            padding: 8px 15px;
            cursor: pointer;

            &.active {
                background: #fff;
                font-weight: 500;
                border-radius: 63px;
            }
        }
    }

    .form-control {
        width: 100%;
        max-width: 300px !important;
        height: 40px;
    }

    .logo-img {
        max-width: 40px;
        margin-right: 15px;
    }

    .table {
        thead {
            th {
                background: #F3F3F3;
                font-weight: 600;
                font-size: 14px;
            }
        }

        tbody {
            td {
                font-size: 14px;
            }
        }


        .copy-clipboard {
            font-size: 12px;
            max-width: 100px;
        }

        .badge {
            padding: 6px 10px;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;

            &.badge-client {
                background: #EAF5EB;
                color: #00B934;
            }

            &.badge-booked {
                color: #ffffff;
                background: #96c0ff;
            }

            &.badge-registered {
                color: #6827D1;
                background: #F3F2FF;
            }
        }
    }
}

.page-admin-add-videos {
    .card {
        background: #FFFFFF;
        box-shadow: 0px 11px 98px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        padding: 30px 20px;
        margin-bottom: 50px;

        h4 {
            font-weight: 600;
            font-size: 24px;
        }

        form {
            margin-top: 30px;

            .custom-file-input-box {
                width: 136px;
                height: 136px;
                position: relative;
                cursor: pointer;

                .input-preview,
                .input-badge {
                    width: 100%;
                    height: 100%;
                    background: #F2F2F2;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-width: 100%;
                        border-radius: 50%;
                    }

                    .fa {
                        font-size: 25px;
                        margin-bottom: 6px;
                    }

                    .text-help {
                        font-size: 14px;
                    }
                }

                .file-input {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    cursor: pointer;
                }

                .edit-icon {
                    position: absolute;
                    top: 0px;
                    right: 10px;
                    background: #f44747;
                    color: #fff;
                    padding: 5px;
                    border-radius: 50%;
                }
            }

            .form-group {
                margin-bottom: 20px;

                label {
                    font-weight: 600;
                    font-size: 14px;
                }

                .form-control {
                    border-radius: 10px;
                    height: 50px;
                    padding: 0 20px;
                    font-size: 14px;
                }

                textarea.form-control {
                    height: 100px !important;
                    padding: 10px 20px;
                }

            }

            .text-helper {
                font-size: 12px;
                margin: 5px 0 0;
            }

            .type-selection {
                display: flex;

                span {
                    flex: 1;
                    background: #F3F3F3;
                    border-radius: 12px;
                    height: 50px;
                    margin: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &.active {
                        font-weight: 500;
                        background: #000000;
                        color: #fff;

                    }
                }
            }
        }
    }
}

.btn.btn-edit {
    padding: 0 10px;
    height: 36px;
    border-radius: 10px;
    color: #fff;
    background-color: #333 !important;
    font-size: 15px;
}

.btn.btn-delete {
    padding: 0 10px;
    height: 36px;
    border-radius: 10px;
    color: #fff;
    font-size: 15px;
}

.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .action-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1;

        a {
            margin: 0 10px;
        }

        .form-control {
            max-width: 170px;
        }
    }
}

.tag-input {
    height: auto !important;
    padding: 5px 20px !important;
    border-color: #ced4da !important;

    .react-tagsinput-tag {
        background: #f4645d;
        border: 1px solid #f44747;
        color: #fff;
    }
}

.check-inline {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    font-weight: 500 !important;
    margin-bottom: 0 !important;

    .form-check {
        margin-right: 5px;
    }
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .tag-item {
        background: #f4645d;
        border: 1px solid #f44747;
        color: #fff;
        padding: 5px 5px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: 500;
    }
}

.custom-link-container {
    overflow: hidden;
    white-space: nowrap;
    max-width: 140px;

    .custom-link {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }
}