/* Search */

input {
	box-sizing: border-box;
	font-size: 13px;
	vertical-align: top;
}

.about-tutor {
	margin-left: -30px;
	margin-right: 30px;
}

.search-container {
	margin-top: 20px;
}
.search-container form {
	display: flex;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	align-items: center;
}
.search-input-box {
	padding: 20px 30px;
	font-size: 16px;
	width: 100%;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	border-radius: 25px;
	border: 0.1px rgb(206, 206, 206) solid;
	outline: none;
}
.btn-submit {
	cursor: pointer;
	border: none;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	background-color: white;
	border-radius: 50%;
	padding: 0px 18px;
	font-size: 14px;
	display: inline-block;
	margin-left: -65px;
	color: black;
	height: 50px;
}

.a-tutor {
	margin: 70px auto;
	background-color: white;
	border-radius: 27px;
	box-shadow: 0 1px 3px 0 rgb(202 206 209);
	text-align: left;
	padding: 25px;
	padding-top: 35px;
	padding-left: 35px;
	width: 100%;
	max-width: 1100px;
}

.a-tutor-alt {
	border-radius: 16px;
	margin: 70px 235px 70px 235px;
	border: none;
	box-shadow: none;
}

.tutor-profile-subjects {
	bottom: 15px;
	position: absolute;
}

.read-more-text {
	color: #f44747;
	/* margin-left: 10px; */
	font-size: 0.9rem;
}

.read-more-text:hover {
	cursor: pointer;
}

@media (max-width: 1024px) {
	.a-tutor {
		margin: 35px auto;
	}
}

.play-button {
	position: relative;
}

/* Search page */

.tutor-statement {
	font-size: 14px;
	font-weight: 300;
}

.tutor-statement-div {
	margin-top: 16px;
}

.book-tutor {
	padding: 0;
	position: relative;
	right: auto;
	margin: 20px auto 10px;
	left: auto;
	width: 100%;
	max-width: 200px;
	height: 56px;
	font-size: 16px;
	background-color: #f44747;
}

.book-tutor:disabled {
	background-color: #f77272;
}

.see-more {
	text-align: center;
}

.tutor-online {
	color: #32cd32;
	font-size: 13px;
}
.tutor-university {
	width: 25px;
}

.university {
	font-size: 18px;
	font-weight: 500;
	color: #9b9b9b;
}

.tutor-search-image {
	width: 130px;
	height: 130px;
	border-radius: 100%;
	margin-bottom: 40px;
}

.play-tutor-video {
	margin-left: -84px;
	font-size: 35px;
	color: white;
}
.play-tutor-video:hover {
	color: #fd5e53;
	transform: scale(1.1);
	transition-duration: 0.2s;
}

.peerlinc-certificate {
	font-size: 16px;
	font-weight: 600;
	position: absolute;
	top: -10px;
	right: 10px;
	background: #f9f9f9;
	border-radius: 12px;
	padding: 12px;
}

.peerlinc-certificate span {
	line-height: 24px;
	vertical-align: middle;
}

.peerlinc-check {
	color: #0fa75d;
	margin-right: 5px;
}

.tutor-reviews {
	margin-left: 75px;
	margin-top: 20px;
}
.tutor-reviews:hover {
	font-weight: bold;
}
.reviews-star {
	color: #fbd46d;
}

.specialties {
	padding-bottom: 40px;
	margin-top: 15px;
}

.subject {
	padding: 7px 12px 7px 12px;
	margin: 10px 15px 0 0;
	font-size: 14px;
	border-radius: 12px;
	font-weight: 500;
	/* box-shadow: 0 1px 3px 0 rgb(184, 184, 184); */
	background: #f1f4fc;
	color: #5b63a8;
}
.subject:hover {
	transform: none;
	cursor: default;
}

@media (max-width: 800px) {
	.tutor-reviews {
		display: none;
	}

	.Peerlinc-certificate {
		/* display: none; */
		position: relative;
		left: 0px;
		padding-left: 0px;
		background: none;
	}

	.tutor-statement-div {
		margin-top: 50px;
	}

	.book-tutor {
		position: relative;
		right: auto;
		margin-top: 28px;
		margin-bottom: 28px;
		left: auto;
	}

	.tutor-profile-subjects {
		/* bottom: 15px; */
		position: relative;
	}
}

@media (max-width: 600px) {
	.book-tutor {
		position: relative;
		right: auto;
		margin-top: 28px;
		margin-bottom: 28px;
		left: auto;
	}

	.a-tutor div {
		margin-left: 0px;
		margin-right: 0px;
	}
}

/* Tutor Profiles */

.profile-container {
	background-color: white;
	border-radius: 27px;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	text-align: center;
	padding: 30px 90px 30px 90px;
	margin: 0 20rem 70px 20rem;
}

.tutor-profile-second {
	margin-top: 50px;
}

.tutor-profile-book {
	margin-top: 15px;
	border-radius: 15px;
	background-color: #eb6158;
	color: white;
	font-size: 16px;
	padding: 12px 20px 12px 20px;
	margin-bottom: 20px;
}

.tutor-profile-chat {
	margin-top: 15px;
	margin-right: 25px;
	background-color: #2c2c2e;
	border-radius: 100%;
	padding: 16px 21px 16px 21px;
	margin-bottom: 20px;
}

.tutor-profile-statement {
	text-align: left;
	padding-top: 15px;
	font-size: 15px;
	font-weight: 400;
	color: #5a5a5a;
}

.tutor-profile-about {
	text-align: left;
	font-size: 30px;
}

.things {
	margin-top: 50px;
	margin-bottom: 40px;
}

.thing {
	margin-top: 5px;
	font-size: 11px;
	font-weight: 100;
	margin-right: 10px;
	padding: 4px;
	color: black;
	background-color: rgb(243, 243, 243);
}
.thing:hover {
	transform: translate(0, -2px);
	transition-duration: 0.3s;
	cursor: default;
}

/* Desktop specific */
@media (min-width: 760px) {
	.specialties-profile {
		margin-top: 150px;
		margin-bottom: 40px;
	}

	.facts {
		margin-top: 150px;
		margin-bottom: 40px;
	}

	.tutor-profile-reviews {
		margin-top: 50px;
	}
}

/* Tutor Profile on smaller screens */

@media (max-width: 760px) {
	.facts {
		display: none;
	}
}

@media (max-width: 1100px) {
	.profile-container {
		margin: 20px 85px 70px 85px;
	}
}

/* Phone Responsiveness */

@media (max-width: 600px) {
	.profile-container {
		padding: 10px 25px 10px 25px;
		margin: 20px 15px 70px 15px;
	}

	.tutor-image {
		margin-left: 0px;
	}

	.play-button {
		right: 6px;
	}

	.tutor-statement-div {
		margin-top: 20px;
	}
}

.box-tutor-details {
	position: relative;
}

/* Tutor Video */

.tutor-image {
	width: 147px;
	height: 147px;
	border-radius: 16px;
	z-index: 1;
	margin-left: 0px;
}

.tutor-image-container {
	height: 147px;
	width: 147px;
	position: relative;
	margin: 0 auto;
}

.modal-video {
	/* margin-left: 20px; */
	border-radius: 15px;
	/* margin-bottom: 20px; */
	z-index: 1;
	border: 0;
}

.profile-video {
	margin-top: 30px;
	border: 0;
	border-radius: 10px;
	margin-bottom: 35px;
}

.tutor-video-play {
	position: absolute;
	bottom: 30px;
	right: 0px;
	font-size: 55px;
	color: rgb(252, 252, 252);
	width: 40px;
	height: 40px;
}

.tutor-video-play:hover {
	/* transform: scale(1.03); */
	cursor: pointer;
}

@media (max-width: 470px) {
	.search {
		width: 75%;
		margin-left: -5px;
		font-size: 13px;
	}

	.search-big {
		width: 75%;
		margin-left: 5px;
		font-size: 13px;
	}

	.a-tutor {
		margin: 20px auto;
		padding: 15px;
		margin-bottom: 45px;
	}

	.modal-video {
		width: 100%;
		height: auto;
	}
	.a-tutor .modal {
		padding-right: 0px;
	}

	.a-tutor .modal-content {
		max-width: 100vw;
	}

	.a-tutor .modal-body {
		align-self: center;
	}
}

.copy-box {
	margin-top: 50px;
}
.copy-box .txt-title {
	font-size: 16px;
	font-weight: 500;
	color: #9b9b9b;
	margin: 0 0 5px;
}
.copy-box .copy-link {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	border: 1px solid #ddd;
	border-radius: 8px;
	cursor: pointer;
}
.copy-box .copy-link .link {
	color: #000;
	font-size: 11px;
	width: calc(100% - 40px);
	padding: 5px 8px;
	text-overflow: ellipsis;
	overflow: hidden;
}
.copy-box .copy-link .icon {
	width: 30px;
	background-color: #eee;
	text-align: center;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
