.create-account {
	margin-bottom: 0px !important;
	padding: 8px 14px 8px 14px !important;
}

.forgot-password {
	margin-top: 16px;
	display: flex;
	margin-left: 6px;
}

.balance-title {
	text-align: left;
	width: 100%;
}

.balance-amount {
	float: left;
}

.modal-body {
	padding: 24px 44px;
}

.modal-body .container {
	padding: 0px !important;
	margin: 0px;
}

.auth-modal-container {
	background-color: white;
	margin: 10% auto;
	border: 1px solid #888;
	width: 60%;
	border-radius: 15px;
}

.modal-design .container input {
	opacity: 1 !important;
	position: relative !important;
	cursor: text !important;
	width: 100%;
}

.modal-design h1 {
	margin-bottom: 32px;
}

.modal-design label {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	font-family: Poppins;
	margin-bottom: 8px;
}

.custom-control {
	padding-left: 0px;
}

.register-here {
	color: #007bff;
	text-decoration: none;
	display: inline-block;
}

.number-box {
	width: 52px;
	height: 60px;
	border: 1px solid #dddddd;
	padding: 16px 20px;
	text-align: center;
	margin-right: 16px;
	border-radius: 12px;
	margin-top: 32px;
	margin-bottom: 32px;
}

@media (max-width: 650px) {
	.auth-modal-container {
		margin: 20% auto;
		width: 75%;
	}

	.number-box {
		margin-right: 6px;
		width: 42px;
		height: 50px;
		padding: 0px;
	}
}

.auth-container {
	width: 400px;
	margin: 0 auto;
	border: 0;
	text-align: center;
}

.auth-logo {
	width: 110px;
	height: auto;
	padding-top: 25px;
	padding-bottom: 25px;
}

.register-button {
	float: right;
}

.auth-logo-div {
	text-align: center;
}

.auth-input {
	border: 0;
	margin-bottom: 15px;
	padding: 10px;
	border-radius: 8px;
	box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
	width: 100%;
}

.auth-button {
	border-radius: 17px;
	margin-bottom: 25px;
	background-color: #f44747;
	color: white;
	padding: 16px 28px 16px 28px;
	font-weight: 500;
	font-size: 16px;
}

.auth-button-url {
	float: right;
}

.auth-header {
	margin-bottom: 30px;
	font-size: 25px;
}

.tutor-statement-apply {
	height: 200px;
}

.who-div {
	text-align: left;
	margin-left: 22px;
	margin-top: 25px;
	margin-bottom: 15px;
}

.who-label {
	margin-right: 8px;
	margin-top: 6px;
	transform: scale(1.35);
}

.who-label:hover {
	cursor: pointer;
	opacity: 1;
}

input[type='radio']:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #e2e2e2;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
}

input[type='radio']:checked:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #292929;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
}

.who-pick {
	margin-right: 25px;
	font-weight: 550;
	font-size: 17.5px;
}

.who-pick:hover {
	cursor: pointer;
	opacity: 1;
}

/* Confirm */

.confirm-container {
	text-align: center;
	margin-top: 50px;
}

.confirm {
	margin-top: 35px;
	width: 250px;
	border: 0;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.confirm-button {
	margin-top: 40px;
	background-color: #282929;
	color: white;
	border-radius: 12px;
	padding: 10px;
	margin-bottom: 30px;
}
.custom-modal .auth-header {
	font-size: 22px;
}
.custom-modal .form-signin {
	text-align: left !important;
}
.custom-modal .form-signin label {
	display: block;
}

.form-signin label {
	float: left;
	color: #333 !important;
}

/* Phone Responsive */

@media (max-width: 470px) {
	.auth-container {
		width: 300px;
		margin: 0 auto;
		border: 0;
		text-align: center;
	}

	.auth-button {
		width: 70%;
	}

	.auth-modal-container {
		width: 75%;
	}

	.auth-input {
		background-color: rgb(235, 235, 235);
		box-shadow: none;
	}

	.confirm {
		background-color: rgb(235, 235, 235);
		box-shadow: none;
	}
}
