button {
	background-color: #292929;
	border-radius: 18px;
	font-weight: 500;
}

.dashboard {
	margin: 40px;
	margin-top: 100px;
}

.tutor-dash-search {
	text-align: right;
}

.alert-dismissible .close {
	width: auto;
}

.users-tutor-mobile-fix-buttons {
	display: flex;
	justify-content: space-around;
}

.tutor-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.btn-delete {
	margin-left: 8px;
	background-color: #f0f1f1;
	color: #555;
	border-radius: 12px;
	padding: 0 20px;
	font-size: 20px;
}

.dashboard h1 {
	padding-left: 15px;
	color: black;
	font-weight: 700;
	font-size: 32px;
	padding-top: 40px;
	margin-bottom: 32px;
}

.dash-header {
	font-size: 20px;
	font-weight: 600;
	text-align: left;
	margin-left: 32px;
	margin-top: 32px;
	width: 100%;
	margin-bottom: -15px;
}

.dash-header-tutor {
	width: auto;
}

.button-primary {
	width: auto;
	padding: 16px 28px;
	background: #f44747;
}

.button-alt {
	width: auto;
	padding: 16px 28px;
	background: white;
	border: 1px solid #f44747;
	color: #f44747;
	margin: 16px;
}

.text-area {
	resize: none;
}

.text-area:focus {
	outline: none;
}

/* Navbar */

.dropdown-custom {
	position: absolute;
	top: 15px;

	right: 25px;
}

.dropdown-menu {
	border-radius: 77px !important;
	border: 0;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.dash-profile {
	width: 44px;
	height: 44px;
	border-radius: 100%;
}

.dropdown-button {
	border-radius: 77px !important;
	background-color: rgb(240, 241, 241);
	padding: 0px;
	width: 90px;
	height: 56px;
}

.dropdown-button:hover {
	transform: scale(1.02);
	transition-duration: 0.02s;
}

.profile-dropdown {
	font-size: 25px;
	color: #232424;
	margin-left: 10px;
}

.dropdown.show .dropdown-menu {
	border-radius: 15px !important;
}

.nav-img-div {
	/* box-shadow: 0 1px 2px 0 rgba(170, 170, 170, 0.2); */
	padding: 5px;
	position: absolute;
	top: 30px;
	left: 45px;
	border-radius: 100%;
}

.nav-img-div:hover {
	transform: scale(1.02);
	transition-duration: 0.02s;
	cursor: pointer;
}

.nav-img {
	width: 90px;
}

.dropdown-button {
	font-size: 18px;
}

.dropdown-button:hover {
	cursor: pointer;
}

.dropdown-item:focus {
	background-color: white !important;
	color: black;
}

.dropdown-toggle::after {
	display: none;
}

.dropdown-logout:hover {
	cursor: pointer;
}

.dropdown-logout:active {
	background-color: white !important;
	color: black;
}

.dropdown-welcome {
	margin: 15px;
	margin-top: 10px;
	font-size: 18px;
}

.dropdown-welcome-name {
	font-weight: bold;
}

/*Referral Button*/
.referral-container {
	position: absolute;
	top: 16px;
	right: 130px;
}

/*Referral Button*/
.referral-container p {
	margin-top: 15px;
	margin-bottom: 0;
}

.referral-button {
	background-color: #f44747;
	padding: 16px 28px 16px 28px;
}

.get-lessons {
	font-size: 14px;
}

.referral-gift {
	top: 50%;
	margin-top: -15px;
	padding-top: 13px;
	margin-right: 7px;
}

/* Containers */

.tutors-container {
	padding: 0px;
	/* height: 812px; */
	overflow-y: auto;
	overflow-x: hidden;
}

.academic-container {
	padding: 18px;
	min-height: 262px;
}

.sessions-container {
	padding: 12px;
	height: 332px;
	margin-bottom: 30px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-left: 0px;
}

.past-sessions-container {
	padding: 12px;
	height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	padding-left: 0px;
}

.student-session-box {
	height: 650px !important;
}

.student-session-box .no-tutors {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.past-sessions-container .no-tutors-image-session {
	margin-top: 0px;
}

.past-sessions-container .dash-header {
	margin-bottom: 28px;
}

.balance-container {
	padding: 10px;
	height: 170px;
	margin-bottom: 30px;
}

.balance-container h3 {
	margin-top: 32px;
	margin-left: 32px;
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 20px;
}

.balance-container h2 {
	margin-left: 32px;
	margin-top: 6px;
	font-weight: 700;
	font-size: 40px;
}

.mastery-container {
	padding: 9px;
	margin-top: -150px;
	min-height: 410px;
}

/* Sessions */

.a-session {
	margin: 25px 10px;
	margin-left: 32px;
	margin-top: 30px;
}

.loading-sessions {
	margin-top: 75px;
}

.cancel-button {
	background-color: #fd5e53;
	border-radius: 55px;
	margin-top: 18px;
	margin-right: -50px;
	padding: 5.5px 12px 4px 12px;
}

.cancel-title {
	margin: 25px 0;
	font-size: 22px;
	font-weight: bold;
}

.cancel-content {
	text-align: left;
	font-size: 19px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	background-color: #f4f5f8;
	margin: 30px 0px;
	padding: 15px;
}

.cancel-confirm {
	padding: 15px;
	border-radius: 20px;
	margin-top: 5px;
	font-weight: bold;
	font-size: 17px;
	background-color: #fd5e53;
	margin-bottom: 30px;
}

.book-button {
	background-color: #232324;
	width: 55%;
	border-style: hidden;
	margin: 100px auto;
	color: white;
}

/* Balance */

.buy-button {
	position: absolute;
	right: 32px;
	background-color: white;
	border-radius: 12px;
	border-style: hidden;
	color: #f44747;
	border: solid 1px #f44747;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 28px;
	padding-right: 28px;
}

.plus {
	font-weight: bold;
	font-size: 22px;
}

.how-hours-work {
	color: gray;
	margin-top: 35px;
	font-size: 15px;
}

.how-hours-work:hover {
	color: lightgray;
	cursor: pointer;
}

.no-hours-button {
	background-color: gray;
}

.no-hours-button:hover {
	transform: scale(1);
}

.sale {
	position: absolute;
	top: -10px;
	left: 5px;
	border-radius: 100%;
	background-color: #ee695f;
	padding: 8px;
	font-size: 14px;
	color: white;
	width: 70px;
	height: 70px;
	text-align: center;
}

.timer {
	position: absolute;
	top: -10px;
	right: 5px;
	border-radius: 100%;
	background-color: #1d1c1c;
	padding: 8px;
	font-size: 14px;
	color: white;
	width: 70px;
	height: 70px;
	text-align: center;
}

.cash-content {
	margin: 50px;
}

.cash-button {
	margin-top: 50px;
	background-color: #21bf73;
}

.cash-text {
	font-size: 1.5rem;
}

/* My Users */

.users-a-tutor {
	margin: 30px 0;
	text-align: left;
	justify-content: start;
	align-items: center;
}

.btn-show-more {
	margin-top: 30px;
	display: block;
}

.users-add-tutor {
	margin-left: 10px;
}

.my-tutors {
	align-items: center;
	text-align: center;
}

.tutor-pic {
	min-width: 72px;
	min-height: 72px;
	max-width: 72px;
	max-height: 72px;
	border-radius: 100%;
}

.student-name {
	margin-left: 10px;
	font-weight: 500;
	font-size: 14px;
}

.users-message-button-container {
	margin-right: 12px;
}

.users-message-button {
	border-radius: 16px;
	width: 56px;
	height: 56px;
	margin-top: 5px;
	background-color: rgb(230, 230, 230);
	color: rgb(92, 92, 92);
	padding: 11px 16px 11px 16px;
	font-size: 17px;
}

.chat-button {
	border-radius: 25px;
	margin-top: 7px;
	padding: 10px 15px 10px 15px;
}

.chat-button-tutors {
	border-radius: 50px;
	margin-top: 7px;
	padding: 17px 22px 17px 22px;
}

.users-book-button {
	border-radius: 12px;
	height: 56px;
	width: 96px;
	margin-top: 5px;
	line-height: 28px;
	background-color: #f44747;
	color: white;
	text-align: center;
}

.users-book-button.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.mytutor-details {
	display: inline-block;
	padding: 0 10px;
}

.mytutor-details h6 {
	width: max-content;
	font-size: 16px;
	font-weight: 500;
}

.mytutor-details .university {
	display: flex;
}

.mytutor-details .university img {
	margin-right: 5px;
}

.search-students-icon {
	margin-left: 5px;
	font-size: 20px;
	border-radius: 27px;
	padding: 8px;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
}

.search-students-icon:hover {
	transform: scale(1.02);
	transition-duration: 0.02s;
	cursor: pointer;
}

.search-students {
	margin-top: 20px;
	padding: 0 10px;
	font-size: 13px;
	width: 65%;
	max-width: 300px;
	height: 45px;
	border: 0;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	border-radius: 20px;
	outline: none;
}

.submit-students {
	cursor: pointer;
	border: none;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	background-color: white;
	background-size: 35px 35px;
	border-radius: 20px;
	margin: 5px;
	padding: 7px 10px;
	font-size: 14px;
	display: inline-block;
	margin-left: -40px;
	margin-top: 25px;
}

.no-tutors {
	/* opacity: 0.8; */
}

.no-tutors-image {
	width: 100%;
	margin-top: 100px;
	margin-bottom: 20px;
	max-width: 200px;
}

.no-tutors-text {
	margin-left: 50px;
	margin-right: 50px;
	margin-bottom: 30px;
}

/* Upcoming Sessions */

.upcoming-session-when {
	font-size: 14.5px;
	font-weight: 500;
	margin-top: 14px;
}

.no-tutors-image-session {
	height: 75px;
	margin-top: 50px;
	margin-bottom: 20px;
}

.sessions-dash {
	font-weight: 900;
	font-size: 24px;
}

.join-button {
	margin-top: 10px;
	margin-left: 5px;
	padding: 12px 28px 12px 28px;
	border-radius: 12px;
	background-color: white;
	color: #f44747;
	border: 1px solid #f44747;
	font-size: 16px;
	font-weight: 500;
}

.join-button:focus {
	border: 1px solid #f44747;
}

.request-deny {
	padding: 16px;
	background-color: #ee695f;
	margin-left: -5px;
	color: rgb(0, 0, 0);
	font-size: 15px;
	color: white;
}

.request-accept {
	padding: 16px;
	background-color: #21bf73;
	margin-left: 3px;
	color: rgb(0, 0, 0);
	font-size: 15px;
	color: white;
}

/* Past Sessions */
.session-details {
	text-align: left;
}

.session-tutor-name {
	color: #4b4c52;
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 0px;
}

.session-date {
	color: black;
	font-weight: 700;
	font-size: 16px;
}

.mark-done {
	background-color: #3f3f44;
	margin-left: 25px;
	padding: 12px 28px 12px 28px;
	font-size: 15px;
}

.confirm-mark-button {
	margin-top: 10px;
	margin-bottom: 15px;
	background-color: #f44747;
	padding: 16px 28px;
	margin-top: 32px;
}

.mark-button {
	margin-top: 2px;
	margin-left: 5px;
	padding: 16px 28px 16px 28px;
	border-radius: 12px;
	background-color: white;
	color: #f44747;
	font-size: 16px;
	font-weight: 500;
	border: 1px solid #f44747;
}

.pick-div {
	margin-top: 35px;
	margin-bottom: 55px;
	justify-content: center;
}

.day-container div {
	width: auto;
	/* margin: auto; */
	margin-bottom: 6px;
}

@media (max-width: 500px) {
	.day-container div {
		place-content: center;
	}
}

.complete-pick {
	margin: 10px;
	font-size: 15px;
	padding: 12px;
	background: #f3f3f3;
}

.complete-pick:hover {
	transform: scale(1.01) !important;
}

.completed-session {
	background-color: #1ca563;
	padding: 10px 15px 10px 15px;
	margin-left: 25px;
	border-radius: 100%;
}

.completed-session:hover {
	transform: none;
	cursor: not-allowed;
}

.report-message {
	height: 200px;
	resize: none;
}

.send-report-text {
	text-align: center;
	font-size: 18px;
	font-weight: 600;
}

.done {
	background-color: #292929;
}

.past-session-tutor {
	font-weight: bold;
	font-size: 18px;
}

.past-session-time {
	font-weight: bold;
	font-size: 18px;
}

.past-session-date {
	font-weight: bold;
	font-size: 18px;
}

/* Academic Advisor */

.advisor-action {
	margin-top: 22px;
}

/* Mastery */

.mastery-content {
	font-size: 19px;
	color: #1ea150;
	margin-top: 28px;
	text-align: left;
	margin-left: 30px;
	font-weight: 500;
}

.mastery-content:hover {
	color: rgb(80, 80, 80);
}

.mastery-content-top {
	margin-top: 0;
}

.mastery-button {
	background-color: #393e46;
	font-size: 13px;
	padding: 8px 15px 8px 15px;
}

@media (max-width: 800px) {
	.mastery-container {
		margin-top: 25px;
		margin-bottom: 15px;
	}

	.balance-container {
		margin-bottom: 25px;
	}
}

@media (max-width: 425px) {
	.mytutor-details {
		padding-left: 0px;
		padding-right: 0px;
	}

	.book-button-container {
		display: flex;
	}

	.x-button-dashboard {
		width: auto !important;
	}

	.day-container {
		margin-left: 24px;
	}
}

/* Payments */

.payments-pic {
	width: 60%;
	margin-bottom: 20px;
}

.powered-by-stripe {
	margin-top: 10px;
	font-size: 14spx;
}

.stripe-container {
	margin-bottom: 30px;
}

.transactions-container {
	background-color: white;
	border-radius: 27px;
	padding: 10px;
	box-shadow: 0 1px 3px 0 rgb(227, 228, 230);
	text-align: center;
	overflow-y: auto;
	max-height: 550px;
	margin: 10px;
}

.payments-student-buy {
	margin-top: 30px;
	margin-bottom: 50px;
}

/* Payment Modal */
.trial-total {
	bottom: -15px;
	left: 20px;
	position: relative;
}

.free-trial-modal {
	text-align: center;
}

.free-trial-modal .modal-body {
	padding: 24px 44px;
}

.free-trial-modal .free-trial-modal-dialog {
	min-width: 665px !important;
}

.free-trial-modal h1 {
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 20px;
}

.free-trial-modal h6 {
	font-weight: 400;
	font-size: 18px;
	margin-bottom: 30px;
	color: #4b4c52;
}

.free-trial-modal .primary-button {
	background-color: #f44747;
	padding: 16px 28px;
	margin-top: 15px;
}

.confirm-box {
	background: #f3f3f3;
	width: 280px;
	/* height: 123px; */
	border-radius: 16px;
	padding: 24px;
}

.confirm-box h6 {
	position: static;
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: 500;
}

.confirm-box h3 {
	color: #4b4c52;
	font-size: 20px;
	font-weight: 400;
	text-transform: capitalize;
}

.confirm-box h2 {
	color: #1a1b1e;
	font-size: 35px;
	font-weight: 700;
}

.confirm-box .price-tag h3 {
	font-size: 16px;
	font-weight: 400;
	margin-top: 15px;
	margin-left: 10px;
}

.total-row {
	margin-top: 32px;
	margin-bottom: 32px;
	display: flex;
}

.total-row h2 {
	font-size: 20px;
	font-weight: 400;
	line-height: 50px;
}

.total-row h3 {
	font-size: 36px;
	font-weight: 700;
	margin-bottom: 0px;
	padding-left: 10px;
}

/* Phone */

@media (max-width: 900px) {
	.upcoming-session-when-container {
		/* margin-left: 20px; */
		font-size: 13px;
		width: 80%;
	}

	.dashboard-button-mobile {
		width: 20%;
	}

	.x-button-dashboard {
		margin-right: -35px;
	}

	.session-details {
		width: 70%;
		margin-left: 36px;
	}

	.session-details-upcoming {
		width: 80%;
	}

	.nav-img {
		display: none;
	}

	.dashboard {
		margin-left: 0px;
		margin-right: 0px;
	}

	.dashboard h1 {
		margin-top: 32px;
	}

	.sessions-container {
		margin-top: 32px;
	}

	.join-button {
		right: 32px;
	}

	.search-students {
		margin-top: -20px;
		margin-right: 20px;
		padding: 0 10px;
		font-size: 13px;
		width: 45%;
		max-width: 300px;
		height: 45px;
		border: 0;
		box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
		border-radius: 20px;
		outline: none;
	}

	.submit-students {
		position: absolute;
		top: -40px;
		right: 40px;
	}

	.cancel-button {
		display: block;
		margin-right: 32px;
	}

	.users-tutor-mobile-fix {
		margin-bottom: 32px;
		display: flex;
	}

	.users-tutor-mobile-fix img {
		margin-right: 16px;
	}

	.mytutor-details {
		top: 10px;
	}

	.mytutor-details-summary {
		margin-left: 14px;
	}

	.users-a-tutor {
		display: flex;
	}

	.users-tutor-mobile-fix-buttons {
		display: flex;
	}

	.users-message-button-container {
		flex: 0.15;
	}
}

/* Slide down animation */

.dropdown .dropdown-menu {
	-webkit-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	-moz-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	-ms-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	-o-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;

	max-height: 0;
	display: block;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
}

.dropdown.show .dropdown-menu {
	-webkit-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	-moz-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	-ms-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	-o-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	transition: max-height 0.3s, opacity 0.2s, visibility 0s;

	margin-top: 15px;
	max-height: max-content;
	opacity: 1;
	visibility: visible;
}

.transaction-history-page .payment-badge.payment-badge-success {
	background: #dfffe6;
	color: #098d16;
}

.transaction-history-page .txt-price {
	font-weight: 500;
	padding-top: 20px;
	display: block;
	font-size: 18px;
}

@media (max-width: 768px) {
	.StripeElement {
		height: 55px !important;
	}

	.StripeElement iframe {
		height: 40px !important;
	}
}

@media (max-width: 480px) {
	.day-container {
		margin-left: 0;
	}
	.day-container > .row {
		margin: 0 5px !important;
	}
	.day-container .row > div {
		width: 50%;
	}
}

@media (max-width: 1024px) {
	.ipad-dashboard {
		flex: auto;
		max-width: 100% !important;
	}

	.ipad-sessions-container {
		flex: auto;
		max-width: 100% !important;

		margin-top: 30px;
	}
}

.dropdown-item.selected {
	color: #f44747;
}
