.availability-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px 10px;

    .calendar-box {
        box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.06);
        border-radius: 14px;
        padding: 15px 20px;
        width: 100%;
        max-width: 310px;


        .text-day-name {
            background: #F5E7E7;
            border-radius: 12px;
            font-weight: 600;
            font-size: 16px;
            color: #000000;
            padding: 10px 8px;
            text-align: center;
        }

        .time-slot-box {
            border: 1px solid #E2D6D6;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 10px 0;
            margin-top: 15px;

            .time-input {
                margin-bottom: 0;
                padding: 10px 5px 10px 5px;
                width: 105px;
            }

            .delete-slot {
                background: transparent;
                color: #A8A8A8;
                font-size: 18px;
                min-height: auto;
                padding: 0;
                margin-left: 5px;
                margin-right: 5px;
                border-radius: 0;
                position: relative;
                height: 30px;
            }
        }

        .btn-add-slot {
            background: transparent;
            padding: 8px 10px;
            color: #F44747;
            font-size: 14px;
            margin-top: 20px;
            text-align: center;
            width: 100%;
        }
    }
}