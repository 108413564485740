.modal-design h1 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #1A1B1E;
    margin: 12px 0px;
}

.modal-design h3 {
    font-size: 16px;
    font-weight: 400;
    color: #4B4C52;
}

.modal-design h4 {
    font-size: 16px;
    font-weight: 400;
}

.modal-design h6 {
    font-weight: 500;
    font-size: 18px;
    color: black;
}

.book-modal .modal-content {
    max-width: 100vw;
    width: 665px;
}

@media (max-width: 600px){
    .modal-content {
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    }

    .book-modal .modal-dialog {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}


.book-modal {
    text-align-last: left;
    width: 88vw;
}

.book-modal .modal-body {
    padding: 14px;
}

.book-modal button {
    width: 100%;
    text-align: center;
    text-align-last: center;
}

.modal-design .dropdown {
    width: 100%;
    height: 66px;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    padding-left: 14px;
    padding-right: 14px;
}

.next-button {
    float: right;
    margin-bottom: 44px;
    min-width: 126px;
    width: auto;
    height: 56px;
    margin-top: 44px;
    background-color: #F44747;
}

.modal-body {
    padding: 25px;
}

.time-button {
    background: #F3F3F3;
    color: #4B4C52;
    padding: 10px;
    width: 200px;
    margin: 10px;
    margin-left: 0px;
    height: 66px;
    font-size: 15px;
    font-weight: 400;
}

@media (max-width: 600px) {
    .time-button{
            width: -webkit-fill-available;
    }

    .book-modal {
        width: 100vw;
    }
}

.a-subject {
    box-shadow: none;
}


.recommendation-button-container {
    text-align: right;
}

.recommendation-button-container .book-first-next {
    padding: 10px 10px 10px 10px;
}

.modal-design-radio .recommendation-button-container {
    padding-left: 0px;
}

/* The container */
.modal-design .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    margin-left: 9px;
    width: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.modal-perfect-tutor .modal-body {
    padding: 15px !important;
}

.modal-design-radio .modal-body .container {
    padding-left: 40px !important;
}

.modal-design-radio .modal-body .container .checkmark {
    margin-left: 10px;
}

/* Hide the browser's default radio button */
.modal-design-radio .container input {
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    display: none;
}

/* Create a custom radio button */
.modal-design .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.modal-design .container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.modal-design .container input:checked~.checkmark {
    background-color: black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.modal-design .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.modal-design .container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.modal-design .container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
}

.a-subject .a-subject-check {
    display: none;
}

.a-subject-active {
    background: #1A1B1E !important;
    color: white !important;
}

.a-subject-active .new-orange {
    color: white;
}

.a-subject-active .a-subject-check {
    display: initial !important;
    position: absolute;
    margin-top: -15px;
    background: white;
    border-radius: 100%;
}

.time-button .a-subject-check {
    display: none;
}


.time-button-active {
    background: #1A1B1E;
    color: white;
}

.time-button-active .new-orange {
    color: white;
}


.time-button-active .a-subject-check {
    display: initial !important;
    position: initial;
    background: white;
    border-radius: 100%;
}

.recommendation-button-container {
    text-align: right;
    /* padding-right: 54px; */
    display: flex;
}

.recommendation-button-container .book-first-next {
    /* padding: 10px 0px 10px 0px; */
    width: 180px;
}
