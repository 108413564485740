/* Settings */

.settings-container {
	padding: 20px;
	margin: 80px 190px 80px 190px;
}

@media (max-width:1024px) {
    .settings-container {
		margin: 120px 90px 80px 90px;
	}
}

.settings-general-container {
	padding: 20px 80px 20px 80px;
}

.tutors-settings-container {
	padding: 0;
	margin: -65px 190px 80px 190px;
}

.settings-header {
	margin-top: 20px;
	margin-bottom: 40px;
	font-size: 30px;
	text-align: left;
}

@media (max-width: 900px) {
	.settings-container {
		padding: 12px;
		margin: 100px 20px 80px 20px;
	}
	.tutors-settings-container {
		padding: 0;
		margin: -70px 20px 80px 20px;
	}
}

.settings-input {
	margin-bottom: 35px;
	width: 250px;
	border: 0;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 rgb(206, 211, 214);
}
.settings-label {
	font-size: 16px;
	font-weight: 600;
}

#upload-photo {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.upload {
	padding: 10px;
	margin: 10px 0 10px 25px;
	border-radius: 15px;
	background-color: #fcfcfc;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.upload:hover {
	cursor: pointer;
}

.settings-box {
	text-align: left;
	margin-left: 50px;
	margin-top: 30px;
}

.settings-save {
	margin-bottom: 25px;
	margin-top: 10px;
	padding: 14px;
	border-radius: 17px;
	background-color: #09ac5d;
}

.settings-image {
	border-radius: 100%;
	width: 95px;
}

.settings-upload {
	margin-left: 20px;
	background-color: #3f4441;
}

/* Tutor Specific Info */
.settings-tutor-statement {
	margin-bottom: 35px;
	width: 90%;
	height: 150px;
	border: 0;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.payment-container {
	margin: 25px 0 30px 0;
	text-align: left;
}

.payment-header {
	text-align: left;
	margin-bottom: 15px;
	margin-top: 40px;
}

.settings-input-payment-card {
	padding: 7px;
	border: 0;
	width: 190px;
	margin-top: 7px;
	border-radius: 8px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.settings-input-payment-date {
	padding: 7px;
	border: 0;
	width: 75px;
	margin-top: 7px;
	border-radius: 8px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.settings-input-payment-cvc {
	padding: 7px;
	border: 0;
	width: 55px;
	margin-top: 7px;
	border-radius: 8px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	margin-right: 25px;
}

.tutors-payment-how {
	margin-top: 40px;
	margin-bottom: 40px;
	font-size: 18px;
}

.facts-and-specialties {
	margin-top: 50px;
	margin-left: 50px;
	text-align: left;
}

.specialty-settings {
	padding: 6px;
	margin: 5px;
	font-size: 13px;
	background-color: rgb(231, 231, 231);
	color: black;
}
.specialty-settings:hover {
	transform: none;
}

.add-settings {
	padding: 7px;
	font-size: 13px;
}

.delete-slot-settings {
	margin-left: 5px;
}

/* Phone Responsiveness */

@media (max-width: 470px) {
	.settings-input {
		background-color: rgb(240, 240, 240);
		box-shadow: none;
	}

	.settings-container {
		padding: 0;
		margin: 90px 20px 20px 20px;
	}

	.settings-general-container {
		padding: 0;
	}

	.tutors-settings-container {
		padding: 0;
		margin: -40px 20px 20px 20px;
	}

	.settings-save {
		margin-top: 25px;
	}
}

/* Billing Method Not Used Yet */

.card-box {
	width: 320px;
	height: 190px;
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
	perspective: 600px;
	display: inline-block;
}

.card__part {
	box-shadow: 1px 1px #aaa3a3;
	top: 0;
	position: absolute;
	z-index: 1000;
	left: 0;
	display: inline-block;
	width: 320px;
	height: 190px;
	background-image: linear-gradient(
		to right bottom,
		#e9e1e1,
		#d1cece,
		#888787,
		#686666,
		#818181
	);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 8px;
	-webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
}

.card__front {
	padding: 18px;
	/* -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0); */
}

.card__back {
	padding: 18px 0;
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
}

.card__black-line {
	margin-top: 5px;
	height: 38px;
	background-color: #303030;
}

.card__logo {
	height: 16px;
}

.card__front-logo {
	position: absolute;
	top: 18px;
	right: 18px;
}
.card__square {
	border-radius: 5px;
	height: 30px;
}

.card_numer {
	display: block;
	width: 100%;
	word-spacing: 4px;
	font-size: 20px;
	letter-spacing: 2px;
	color: #fff;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 20px;
}

.card__space-75 {
	width: 75%;
	float: left;
}

.card__space-25 {
	width: 25%;
	float: left;
}

.card__label {
	font-size: 10px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.8);
	letter-spacing: 1px;
}

.card__info {
	margin-bottom: 0;
	margin-top: 5px;
	font-size: 16px;
	line-height: 18px;
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.card__back-content {
	padding: 15px 15px 0;
}
.card__secret--last {
	color: #303030;
	text-align: right;
	margin: 0;
	font-size: 14px;
}

.card__secret {
	padding: 5px 12px;
	background-color: #fff;
	position: relative;
}

.card__secret:before {
	content: '';
	position: absolute;
	top: -3px;
	left: -3px;
	height: calc(100% + 6px);
	width: calc(100% - 42px);
	border-radius: 4px;
	background: repeating-linear-gradient(
		45deg,
		#ededed,
		#ededed 5px,
		#f9f9f9 5px,
		#f9f9f9 10px
	);
}

.card__back-logo {
	position: absolute;
	bottom: 15px;
	right: 15px;
}

.card__back-square {
	position: absolute;
	bottom: 15px;
	left: 15px;
}

.card:hover .card__front {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
}

.card:hover .card__back {
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
}
