/* Availibility */

.avail-header {
	margin-bottom: 45px;
	text-align: left;
}

.settings-calendar-avail-day-of-week {
	width: 100%;
}

.settings-calendar-avail {
	text-align: left;
	font-size: larger;
	margin-bottom: 25px;
}

.time-slot {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	margin-right: 20px;
	width: 170px;
	padding: 10px;
	text-align: center;
}

.time-input {
	border: 0;
	padding: 10px;
	margin-bottom: 12px;
	border-radius: 5px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.time-edit {
	color: #3f4441;
}

.add-slot {
	padding: 5px 12px 5px 12px;
	background-color: #242424;
	font-size: 14px;
}

.delete-slot {
	font-size: 10px;
	padding: 2px 6.5px 1px 6.5px;
	border-radius: 50px;
	background-color: #fd5e53;
	position: sticky;
	animation: ease-in-out;
}
.delete-slot:hover {
	transform: scale(1.1);
}

.settings-calendar-avail-day {
	font-size: 16px;
}

/* Book System */
.book-button-container {
	text-align-last: right;
	margin-right: -15px;
	display: flex;
}

.book-content {
	margin: 20px;
	text-align: center;
}

.book-content-container {
	padding: 1px;
}

@media (max-width: 500px) {
	.book-content-container * {
		margin-bottom: 5px;
	}
}

.book-header {
	margin-bottom: 35px;
	margin-top: 0;
}

.book-text-area {
	border-radius: 17px;
	border: 0;
	padding: 12px;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	width: 90%;
	margin-top: 30px;
}

.book-first-next {
	/* float: right; */
	margin-top: 40px;
	padding: 10px 15px 10px 15px;
	margin-bottom: 15px;
	border-radius: 17px;
	width: 164px;
	margin-right: -15px;
	height: 56px;
	background-color: #F44747;
}


.book-prev {
	/* float: right; */
	margin-top: 40px;
	width: 164px;
	height: 56px;
	margin-right: 20px;
	padding: 10px 15px 10px 15px;
	margin-bottom: 15px;
	border-radius: 17px;
	color: #F44747;
	background-color: white;
	border: solid 1px #F44747;
}

.tutor-inactive {
	margin-top: 25px;
	margin-left: 35px;
}

.dates {
	margin-left: -15px;
}

.book-date-day {
	width: 122px;
	height: 48px;
	border-radius: 8px;
	margin: 12px;
	margin-left: 8px;
	margin-right: 8px;
	background-color: #F3F3F3;
	padding: 0;
	color: black;
	overflow: hidden;
	white-space: nowrap;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.book-date-day:hover {
	transform: scale(1.05);
	transition-duration: 0.1s;
	cursor: pointer;
}
.book-date-day:focus {
	background-color: #292929;
	outline: none;
	color: white;
}

.loading-days {
	margin-left: 215px;
}

.book-a-date {
	font-size: 14px;
}

.book-explain {
	margin-top: -15px;
}

.book-content-summary-top {
	display: flex;
}

.book-content-summary-top h6 {
	font-weight: 400;
	font-size: 18px;
}

.book-content-summary-top h5 {
	font-weight: 600;
	font-size: 18px;
	padding-left: 9px;
}

.book-content-summary {
	text-align: left;
	font-size: 17px;
	box-shadow: 0 1px 3px 0 rgb(202, 206, 209);
	border-radius: 15px;
	background-color: #fdfcfc;
	padding: 10px;
	margin-top: 32px;
	margin-bottom: 32px;
}

.book-content-summary-header {
	font-size: 12px;
}
.book-content-summary-value {
	padding-left: 0px;
	padding-right: 0px;
	font-weight: bold;
	font-size: 16px;
}

.book-content-summary-row {
	margin-left: 10px;
}

.modal-header .close {
	text-align-last: right;
}

.session-with {
	min-width: max-content;
	color: #1A1B1E;
	font: weight 600;
	font-size: 18px;
	opacity: 1;
	margin-left: 20px;
}

@media (max-width: 500px) {
	.session-with {
		 color: #1A1B1E;
		font-size: 14px;
		margin-left: 0px;
	}
   
}

.session-with text {
	font-weight: 700;
}

.cancel-confirm {
	text-align: center;
}

.cant-cancel {
	margin: 20px;
}


/* Done modal */

.done-container {
	text-align-last: center;
    width: 100%;
}

.done-container img {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 32px;
}

.done-container h1 {
	font-size: 26px;
	font-weight: 700;
	color: #000;
}

.done-container h3 {
	margin-top: 12px;
	font-size: 18px;
	font-weight: 400;
}

.done-container h5 {
	color: #000000;
	margin-top: 32px;
	font-weight: 600;
	font-size: 18px;
	width:80%;
	margin-right: auto;
	margin-left: auto;
}

.done-container h6 {
	color: #000000;
	margin-top: 12px;
	font-weight: 700;
	font-size: 26px;
	width:80%;
	margin-right: auto;
	margin-left: auto;
}

.stripe-card{
	margin: 20px 0;
}
/* Phone */

@media (max-width: 765px) {
	.time-slot {
		margin-bottom: 30px;
	}
}

@media (max-width: 470px) {
	.book-content {
		margin: 15px;
	}
	.book-text-area {
		background-color: rgb(235, 235, 235);
		box-shadow: none;
	}

	.book-date-day {
		width: 74px;
		margin: 2px;
	}
	.book-date-day:hover {
		background-color: #292929;
		outline: none;
		color: white;
	}

	.book-a-date {
		font-size: 11px;
	}

	.book-header {
		text-align: left !important;
		margin-left: -15px;
	}

	.modal-body {
		padding: 20px 24px;
	}

	.book-content-summary {
		min-width: 75vw;
	}
}
