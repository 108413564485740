/* General */
.all-labels {
	color: gray;
	font-size: 12px;
	margin-bottom: 15px;
	margin-top: 45px;
}

.admin-nav-image {
	width: 65px;
	margin-top: -6px;
}

.admin-student-pick {
	text-align: center;
	margin: 15px 390px 25px 390px;
}

.admin-pick {
	padding: 15px 20px;
	background-color: rgb(223, 223, 223);
	color: black;
}

.admin-picked {
	padding: 15px 20px;
	background-color: black;
	color: white;
}

.a-data {
	margin-top: 30px;
	border: 1px lightgray solid;
	border-radius: 15px;
	padding: 15px;
}

@media (max-width: 500px) {
	.a-data {
		font-size: 10px;
	}
}

.a-data:hover {
	border: 1px #dd4b46 solid;
	cursor: pointer;
}

.admin-image {
	margin-top: 30px;
	width: 85px;
}

.admin-dropdown:hover {
	cursor: pointer;
	color: rgb(146, 145, 145);
}

/* Containers */
.admin-container {
	padding: 20px;
}

.all-students-container {
	margin-top: 45px;
}

/* Sessions */

.admin-sessions {
	font-size: 15px;
	margin-top: 10px;
	border-radius: 15px;
	height: 150px;
	overflow-y: auto;
}

.recent-admin {
	height: 490px;
	overflow-y: auto;
}

.sessions-when {
	text-align: center;
	width: 100%;
	max-width: 600px;
	margin: 30px auto 20px;
}

@media (max-width: 1150px) {
	.sessions-when {
		padding: 0;
	}
}

.when-pick {
	border-radius: 8px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.when-pick:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.when-pick:active {
	background-color: #0779e4;
	color: white;
}

.session-done {
	color: #21bf73;
}

.session-pending {
	color: gray;
}

.session-cancelled {
	color: #fd5e53;
}

/* Admin Actions */

.update-balance {
	background-color: #21bf73;
	margin-bottom: 25px;
}

.delete-user {
	background-color: #fd5e53;
	margin-left: 25px;
}

.certify-tutor {
	background-color: #21bf73;
}

/* Tutors */

.tutor-bio {
	text-align: left;
	margin: 0 50px 20px 50px;
	font-size: 12px;
}

/* Data */

.data-title {
	margin-left: 5px;
	margin-bottom: 15px;
}

.when-data {
	font-size: 14px;
	margin-left: 5px;
	opacity: 0.8;
}

.when-data:hover {
	color: black;
	cursor: pointer;
}

.when-data-pick {
	opacity: 1;
	color: black;
}

/* Certify */

.certified {
	color: #21bf73;
}

.not-certified {
	color: #fd5e53;
}

.manual-subscription {
	margin: 20px 0;
}

.payment-badge {
	border-radius: 8px;
	padding: 10px 16px;
	text-transform: capitalize;
}

.payment-badge.payment-badge-warning {
	background: #fff9ef;
	color: #f47b47;
}

.payment-badge.payment-badge-danger {
	color: #f44747;
	background: #ffefef;
}

.payment-badge.payment-badge-success {
	background: #dfffe6;
	color: #098d16;
}

.payment-badge.payment-badge-success-2 {
	background: #56ba6c;
	color: #fff;
}

.payment-badge.payment-badge-info {
	background: rgb(140, 140, 140);
	color: #fff;
}

.page-subscriptions .page-heading-txt {
	font-weight: 600;
	font-size: 28px;
	line-height: 42px;
	color: #1a1b1e;
}

.page-subscriptions .all-labels {
	background: #f3f3f3;
	color: #1a1b1e;
	padding: 15px 20px;
	margin-top: 10px;
}

.page-subscriptions .all-labels .txt-heading {
	font-weight: 600;
	font-size: 18px;
}

.item-data {
	margin-top: 0px;
	border-bottom: 1px lightgray solid;
	padding: 25px 20px 0px 20px;
	font-size: 16px;
}

.page-analytic {
	background: #f4f4f4;
}

.analytic-box {
	background-color: #ffffff;
	padding: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.04);
	border-radius: 12px;
	text-align: center;
}

.data-title {
	font-size: 20px;
	line-height: 30px;
	color: #4b4c52;
	font-weight: bold;
	opacity: 0.7;
}

.analytic-box .txt-title {
	color: #4b4c52;
	font-weight: 400;
	font-size: 20px;
}

.analytic-box .txt-value {
	color: #000;
	font-weight: 400;
	font-size: 28px;
}

.analytic-box .txt-value span {
	font-weight: 600;
}

.switch-type {
	background: #f3f3f3;
	border-radius: 80px;
	padding: 12px 6px;
	width: 100%;
	max-width: 270px;
	float: right;
	height: 50px;
	cursor: pointer;
}

.switch-type > span.active {
	background: #fff;
	color: #4b4c52;
	font-weight: 600;
	font-size: 14px;
	opacity: 1;
	border-radius: 80px;
}
.switch-type > span {
	color: #1a1b1e;
	padding: 10px 20px;
	opacity: 0.7;
}

/* Phone Responsiveness */

@media (max-width: 800px) {
}
